import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { ContactImageWrapper, ContactSection,FormField,ContactImage, SendMessageButton } from '../components/styledComponents';
import ContactSvg  from '../assets/img/contact_left.png';



const ContactUsPage = () => {
  return (
    <ContactSection id="contact">
      <Container>
        <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} alignItems="center">
          <ContactImageWrapper flex={1}>
            <ContactImage src={ContactSvg} alt="Contact Us" />
          </ContactImageWrapper>

          <Box flex={1}>
            <Typography variant="subtitle1" textAlign={"left"} color="red">
              Contact Us
            </Typography>
            <Typography variant="h3"  textAlign={"left"} fontWeight="bold" gutterBottom>
              Get in Touch! Reach <br /> Out to Us Today
            </Typography>

            <form id="cs_form">
              <Box display="flex" justifyContent="space-between">
                <FormField label="Full Name" variant="outlined" required />
                <Box width={30} />
                <FormField label="Email" variant="outlined" required />
              </Box>

              <Box display="flex" justifyContent="space-between">
                <FormField label="Mobile" variant="outlined" required />
                <Box width={30} />
                <FormField label="Subject" variant="outlined" required />
              </Box>

              <FormField
                label="Write Details"
                variant="outlined"
                required
                multiline
                rows={7}
              />

              <SendMessageButton variant="contained"  type="submit">
                Send Message
              </SendMessageButton>
            </form>
          </Box>
        </Box>
      </Container>
    </ContactSection>
  );
};

export default ContactUsPage;
