import React from 'react';
import { Box, Typography } from '@mui/material';
import { FooterWrapper, FooterLogo, FooterText, SocialLinks, FooterCopyright, StyledIconButton } from './styledComponents';
import Logo from '../assets/img/logo.png';
import Vector1Svg from '../assets/img/Vector1.svg';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';


const Footer = () => {
  return (
    <FooterWrapper>
      <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} justifyContent="space-between">

        <Box>
          <Box sx={{ position: 'absolute', bottom: 140, right: 140 }}>
            <img src={Vector1Svg} alt="Footer Shape" />
          </Box>

          <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }} justifyContent="space-between">
            <Box flex={1}  padding={"20px 40px"} textAlign={"left"} >
              <FooterLogo src={Logo} alt="Logo" />
              <FooterText>
                Ours Software is the ultimate solution designed to <br /> transform your business operations into a
                streamlined <br />and efficient powerhouse. With a focus on simplicity, <br /> versatility, and cutting-edge technology.
              </FooterText>

              <SocialLinks>
                <StyledIconButton component="a" href="https://www.facebook.com/profile.php?id=61567248397215" target="_blank" aria-label="Facebook">
                  <i className="fa-brands fa-facebook-f"></i>
                </StyledIconButton>
                <StyledIconButton component="a" href="https://www.tiktok.com/@spareparts.360" target="_blank" aria-label="Twitter" >
                  <i className="fa-brands fa-tiktok"></i>
                </StyledIconButton>
                <StyledIconButton component="a" href="https://wa.me/923353654321" target="_blank" aria-label="WhatsApp" >
                  <i className="fa-brands fa-whatsapp"></i>
                </StyledIconButton>


              </SocialLinks>
            </Box>


          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', lg: 'row' }} padding={"20px 40px"}>

          <Box textAlign="left">
            <Typography fontSize="40px" fontWeight="700" color="white" mb='20px'>Contact us</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <LocationOnIcon sx={{ color: 'white', mr: 2 }} />
              <Typography variant="body2" color="white" fontSize="18px">
              Main Peshawar Road, Lane 3-B, House 311, Rawalpindi             
               </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <PhoneIcon sx={{ color: 'white', mr: 2 }} />

              <Typography variant="body2" color="white" fontSize="18px">+923015546284, 0518444580 </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ color: 'white', mr: 2 }} />
              <Typography variant="body2" color="white" fontSize="18px">info@spareparts.com</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <FooterCopyright>
        <Typography variant="body2">
          &copy; 2024. Design by Koncept Software Solution
        </Typography>
      </FooterCopyright>
    </FooterWrapper>
  );
};

export default Footer;
