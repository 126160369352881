import React, { useState, useEffect, useMemo } from 'react';
import { Toolbar, Container, Box, IconButton, Drawer, List, ListItem, Typography } from '@mui/material';
import { StyledAppBar, StyledNavLink, StyledLogo, StyledButton1, StyledButton2 } from './styledComponents';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../assets/img/logo.png';

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const menuItems = useMemo(() => [
    { text: 'Home', href: '#HomePage' },
    { text: 'Features', href: '#features' },
    { text: 'Pricing', href: '#pricing' },
    { text: 'Faq', href: '#faq' },
    { text: 'Contact', href: '#contact' },
  ], []);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = menuItems.map((item) => {
        const section = document.querySelector(item.href);
        return {
          id: item.href.slice(1),
          offsetTop: section?.offsetTop || 0,
          offsetHeight: section?.offsetHeight || 0
        };
      });

      const scrollPosition = window.scrollY + window.innerHeight / 3;

      for (let i = 0; i < sectionOffsets.length; i++) {
        const section = sectionOffsets[i];
        if (
          scrollPosition >= section.offsetTop &&
          scrollPosition < section.offsetTop + section.offsetHeight
        ) {
          setActiveSection(section.id);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuItems]);

  const handleClick = () => {
    window.location.href = "http://thesfb.live/sparepart360/auth-pages/login";
  };

  const handleSignup = () => {
    window.location.href = "http://thesfb.live/sparepart360/auth-pages/register";
  };

  return (
    <StyledAppBar position="sticky">
      <Container>
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <a href="index.html">
              <StyledLogo src={Logo} alt="Logo" />
            </a>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, mx: 'auto' }}>
            {menuItems.map((item) => (
              <StyledNavLink
                key={item.text}
                href={item.href}
              >
                {item.text}
                {activeSection === item.href.slice(1) && (
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: 'red',
                      position: 'absolute',
                      bottom: -4,
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  />
                )}
              </StyledNavLink>
            ))}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <StyledButton2 href="#" variant="text" onClick={handleClick}>
              Login
            </StyledButton2>

            <StyledButton1 variant="contained" onClick={handleSignup} sx={{ display: { xs: 'none', md: 'inline-block' } }}>
              Sign Up
            </StyledButton1>

            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: '10px' }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: { 
            width: '100%', 
            backgroundColor: '#121212'
          }
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <Typography variant="h6" sx={{ color: 'white' }}>
            <img src={Logo} alt="Logo" style={{ height: '40px' }} /> 
          </Typography>
          <IconButton onClick={toggleDrawer(false)} style={{margin:'-12px -5px 0px 10px'}} > 
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>

        <List sx={{ padding: '12px 15px',boxSizing:'border-box', color: 'white' }}>
          {menuItems.map((item) => (
           <ListItem 
           button 
           key={item.text} 
           sx={{ display: 'block !important', paddingLeft: '0px' }}
           onClick={() => {
             const section = document.querySelector(item.href);
             if (section) {
               section.scrollIntoView({ behavior: 'smooth' });
             }
             setIsDrawerOpen(false); 
           }}
         >
           <a 
             href={item.href} 
             style={{ textDecoration: 'none', color: 'inherit', width: '100%', textAlign: 'center', fontSize: "18px", lineHeight: '22px' }}
           >
             {item.text}
           </a>
         </ListItem>
          ))}
        </List>
      </Drawer>
    </StyledAppBar>
  );
};

export default Header;
