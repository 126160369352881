import React from 'react';
import { Typography, CardContent, Box } from '@mui/material';
import { Section, Title, Subtitle, StyledCard, LearnMoreButton, StyledContainer, StyledIconBox } from "../components/styledComponents";
import ShopSvg from '../assets/img/shop.svg';
import PriceBarSvg from '../assets/img/price_bar.svg';
import SetupSvg from '../assets/img/setup.svg';
import UISvg from '../assets/img/ui.svg';
import ArrowSvg from '../assets/img/arrow.svg';
import Arrow1Svg from '../assets/img/arrow1.svg';
import IllustrationSvg from '../assets/img/illustartion1.svg';
import Illustration2Svg from '../assets/img/illustartion2.svg';
import GallerySection from './GalaryPage';

const FeaturesPage = () => {
  const features = [
    { imgSrc: ShopSvg, title: 'Suitable For All Spartparts Businesses', description: 'Whether you\'re a small startup or an established enterprise, our platform is designed to cater to a wide range of business needs, ensuring flexibility and growth for every business type.' },
    { imgSrc: PriceBarSvg, title: 'Cost Effective With Affordable Price', description: 'Experience premium features at a fraction of the cost, making our solution the perfect balance between quality and affordability, ensuring you get the best value for your investment.' },
    { imgSrc: SetupSvg, title: 'Easy to Setup & No IT knowledge Need', description: 'Get started in minutes with our user-friendly setup process that requires no technical knowledge. We\'ve simplified everything so you can focus on what matters most—your business.' },
    { imgSrc: UISvg, title: 'Modern & Attractive User Dashboard', description: 'Enjoy a sleek and intuitive dashboard that enhances productivity, with a visually appealing design that\'s as functional as it is attractive.' }
  ];

  return (
    <React.Fragment>
      <Section id="features">
        <StyledContainer maxWidth="lg">
          <Subtitle variant="h6">Grow Your Business With Poze</Subtitle>
          <Title variant="h3">Delightfully simple and surprisingly efficient, our system streamlines sales.</Title>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" >
            {features.map((feature, index) => (
              <Box key={index} width={{ xs: "100%", sm: "50%", md: "24%" }} display="flex">
                <StyledCard style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <CardContent style={{ flexGrow: 1, textAlign: 'left' }}>
                    <img src={feature.imgSrc} alt="Icon" style={{ marginBottom: 16, height: 60 }} />
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{feature.title}</Typography>
                    <Typography variant="body1" style={{ marginBottom: 16, marginTop: 8, color: 'gray' }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                  <Box display="flex" alignItems="center" >
                    <LearnMoreButton variant="contained">Learn More</LearnMoreButton>
                    <img src={ArrowSvg} alt="Arrow" />
                  </Box>
                </StyledCard>
              </Box>
            ))}
          </Box>
        </StyledContainer>
      </Section>
      
      {/* <Section>
        <StyledContainer maxWidth="lg">
          <Subtitle variant="h6">Showcasing Our POS Software</Subtitle>
          <Title variant="h3">Efficient, Intuitive, and Built to Streamline Your Business Operations</Title>
          <GallerySection />
        </StyledContainer>
      </Section> */}
      <Section>
        <StyledContainer maxWidth="lg">
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
            <Box width={{ xs: "100%", md: "50%" }} textAlign="center">
              <img src={IllustrationSvg} alt="" style={{ maxWidth: '100%' }} />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Typography variant="subtitle1" fontSize={18} color="red" fontWeight="bold" gutterBottom>Software Key Features</Typography>
              <Typography variant="h3" component="h2" fontWeight="bold">Powerful Features with Incredible Design</Typography>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                {['Inventory Management', 'Sales & Payment Process', 'Report and Analysis', 'Secure Fast Transaction'].map((title, index) => (
                  <Box key={index} width={{ xs: "100%", sm: "50%" }}>
                    <StyledIconBox>
                      <Typography variant="body1" style={{ color: '#fff', backgroundColor: 'red', borderRadius: '50%', width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 12, fontWeight: 'bold', fontSize: '20px' }}>
                        {"0" + (index + 1)}
                      </Typography>
                      <Typography variant="h6" sx={{ textAlign: "left", fontWeight: 'bold' }}>{title}</Typography>
                      <Typography variant="body2" sx={{ textAlign: "left" }}>Pos can analyze large volumes of data quickly and accurate, valuable.</Typography>
                    </StyledIconBox>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </StyledContainer>
      </Section>

      {/* New Section */}
      <Section>
        <StyledContainer maxWidth="lg">
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
            <Box width={{ xs: "100%", md: "50%" }}>
              <Typography variant="subtitle1" fontSize={18} color="red" fontWeight="bold" gutterBottom>
                Who Can Use Our Poze
              </Typography>
              <Typography variant="h3" component="h2" fontWeight="bold">
                Our Software Capable for Wide Range of Business and Industries
              </Typography>
              <Box sx={{ height: 40 }} />
              <Box display="flex" flexWrap="wrap">
                {[
                  'Automobile Spare Parts',
                  'Industrial Equipment Parts',
                  'Machinery Spare Parts',
                  'Electronics Components',
                  'HVAC Spare Parts',
                  'Marine Spare Parts',
                  'Aviation Spare Parts',
                  'And Many More..'
                ].map((item, index) => (
                  <Box key={index} display="flex" alignItems="center" mb={2} width={{ xs: "100%", sm: "50%" }}>
                    <img src={Arrow1Svg} alt="Icon" style={{ marginRight: 8 }} />
                    <Typography variant="body1" sx={{ fontSize: "18px", lineHeight: '1.5rem' }}>{item}</Typography>
                  </Box>
                ))}
              </Box>

            </Box>

            <Box width={{ xs: "100%", md: "50%" }} textAlign="center">
              <img src={Illustration2Svg} alt="Illustration" style={{ maxWidth: '100%' }} />
            </Box>
          </Box>
        </StyledContainer>
      </Section>
    </React.Fragment>
  );
};

export default FeaturesPage;
