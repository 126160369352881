import React, { useState } from 'react';
import { Container, Box, Typography, Dialog, DialogContent, IconButton } from '@mui/material';
import { StyledButton1, StyledButton3 } from '../components/styledComponents';
import imageshowcase from '../assets/img/showcase.png';
import CloseIcon from '@mui/icons-material/Close';
import demoVideo from '../assets/video/demo.mp4';

const HomePage = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    window.location.href = "http://thesfb.live/sparepart360/auth-pages/register";
  };

  const handleDemoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section style={{ position: 'relative', textAlign: 'center', padding: '80px 0', backgroundColor: '#000' }} id="HomePage">
      <Container>
        <Box sx={{ marginTop: '100px' }}>
          <Typography variant="h2" sx={{ color: 'white', fontWeight: 700, fontSize: '4rem' }}>
            Maximize sales with our advanced software solutions
          </Typography>
          <Typography variant="body1" sx={{ color: 'gray', marginTop: '16px' }}>
            Our advanced software is designed to revolutionize your business operations. With its powerful features and
            <br />
            user-friendly interface, enhance customer experiences, and boost your overall efficiency.
          </Typography>
        </Box>

        <Box sx={{ marginTop: '40px' }}>
          <StyledButton3
            variant="contained"
            onClick={handleDemoClick}
            sx={{ marginBottom: { xs: '10px', sm: 0 }, minWidth: { xs: "200px" } }}
          >
            Product Demo
          </StyledButton3>
          <StyledButton1
            variant="contained"
            onClick={handleClick}
            sx={{ marginLeft: { md: "10px" }, minWidth: { xs: "200px" } }}
          >
            Start Free Trial
          </StyledButton1>
        </Box>

        <Box sx={{ marginTop: '50px' }}>
          <img src={imageshowcase} alt="Thumbnail" style={{ width: '100%', maxWidth: '1320px', marginTop: '20px' }} />
        </Box>
      </Container>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent sx={{ position: 'relative', padding: 0, overflow: 'hidden' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              color: '#fff',
              zIndex: 999,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <video
              src={demoVideo}
              controls
              autoPlay
              style={{ width: '100%', maxHeight: '500px' }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default HomePage;
