import React, { useState } from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaqSection } from '../components/styledComponents';

const FaqPage = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <FaqSection id="faq">
      <Box sx={{ height: '100px', display: 'block'  }} />
      <Container>
        <Box textAlign="center">
          <Typography variant="subtitle1" fontSize={"18px"} color="red" mb={3}>
            Have Any Questions?
          </Typography>
          <Typography variant="h3" fontSize={"32px"} fontWeight={"bold"} gutterBottom>
            Here Are Some Questions Answered
          </Typography>
        </Box>

        <Box sx={{ height: '45px', display: { xs: 'none', lg: 'block' } }} />

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: { xs: '100%', md: '80%', lg: '60%' } }}>
            <Box>
              {faqData.map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  sx={{
                    mb: 2,
                    borderRadius: '8px', 
                    border: '1px solid rgba(0, 0, 0, 0.1)', 
                    boxShadow: 'none', 
                    backgroundColor: 'transparent',
                    "&:before": { display: "none" },
               
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}bh-content`}
                    id={`panel${index}bh-header`}
                    sx={{
                      padding: '16px 24px',
                      backgroundColor: 'transparent', // No background color
                      "& .MuiAccordionSummary-content": {
                        margin: '0px', // Keep content margins clean
                      },
                      "& .MuiAccordionSummary-expandIconWrapper": {
                        marginLeft: 'auto', // Move expand icon to the far right
                      },
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: '16px 24px', backgroundColor: 'transparent' }}>
                    <Typography>{item.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box sx={{ height: '150px', display: { xs: 'none', lg: 'block' } }} />
    </FaqSection>
  );
};

const faqData = [
  {
    question: 'How Does a POS System Benefit My Business?',
    answer: 'To choose the right POS system, consider your business type, size, budget, required features, and potential for future growth. Research various options and read reviews to make an informed decision.',
  },

  {
    question: 'Do I Need Special Training to Use a POS System?',
    answer: 'Most modern POS systems are user-friendly and require minimal training. However, some familiarity with technology can be beneficial.',
  },
  {
    question: 'What Kind of Sales Analytics Does a POS System Provide?',
    answer: 'POS systems typically offer detailed sales reports, customer insights, and inventory tracking to help you make informed business decisions.',
  },
  {
    question: 'How Can I Choose the Right POS System for My Business?',
    answer: 'Research your options, consider your business needs, and read reviews to find a POS system that fits your specific requirements.',
  },
];

export default FaqPage;
