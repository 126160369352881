import { AppBar, Box, Button, Card, Container, TextField,Typography ,IconButton } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const rightToLeft = keyframes`
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
`;

export const StyledAppBar = styled(AppBar)({
  backgroundColor: 'black',
  boxShadow: 'none',
  position: 'fixed',
});

export const StyledNavLink = styled(Button)({
  color: 'white',
  textTransform: 'none',
  marginRight: '20px',
  transition: 'color 0.3s ease',
  fontWeight: 'bold',
  padding: '10px',
  '&:hover': {
    color: 'red',
  },
});

export const StyledLogo = styled('img')({
  height: '40px',
  marginLeft: '5px',
});

export const StyledButton1 = styled(Button)({
  backgroundColor: 'red',
  textTransform: 'none',
  color: 'white',
  backgroundSize: '200% 100%',
  backgroundImage: 'linear-gradient(to left, red 50%, white 50%)',
  backgroundPosition: '100% 100%',
  transition: 'color 0.3s ease, background-position 0.4s ease',
  fontWeight: 'bold',
  padding: '10px 30px', 
  fontSize: '16px',

  '&:hover': {
    animation: `${rightToLeft} 0.4s forwards`,
    backgroundPosition: '0% 100%',
    color: 'red',
  },
});

export const StyledButton2 = styled(Button)({
  textTransform: 'none',
  color: 'white',
  fontWeight: 'bold',
  '&:hover': {
    color: 'red',
  },
});


export const StyledButton3 = styled(Button)({
  backgroundColor: 'white',
  textTransform: 'none',
  color: 'black',
  border: 'none',
  backgroundSize: '200% 100%',
  backgroundImage: 'linear-gradient(to left, white 50%, red 50%)',
  backgroundPosition: '100% 100%',
  transition: 'color 0.3s ease, background-position 0.4s ease',
  fontWeight: 'bold',
  padding: '10px 30px', 
  fontSize: '16px',
  '&:hover': {
    animation: `${rightToLeft} 0.4s forwards`,
    backgroundPosition: '0% 100%',
    color: 'white',
  },
});


export const SendMessageButton = styled(Button)({
  marginLeft: '10px',
  backgroundColor: 'red',
  textTransform: 'none',
  color: 'white',
  border: 'none',
  backgroundSize: '200% 100%',
  backgroundImage: 'linear-gradient(to left, red 50%, black 50%)',
  backgroundPosition: '100% 100%',
  transition: 'color 0.3s ease, background-position 0.4s ease',
  fontWeight: 'bold',
  padding: '10px 30px', 
  fontSize: '16px',
  '&:hover': {
    animation: `${rightToLeft} 0.4s forwards`,
    backgroundPosition: '0% 100%',
    color: 'white',
  },
});

export const Section = styled('section')({
  padding: '64px 0',
  backgroundColor: '#fff',
});

export const Title = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '20px',
  textAlign: 'center',
  width: '80%',

});

export const Subtitle = styled(Typography)({
  color: '#dc004e',
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: '20px',
});

export const StyledCard = styled(Card)({
  padding: '20px',
  textAlign: 'center',
  borderRadius: '16px',
  boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)'
  }
});

export const LearnMoreButton = styled(Button)({
  color: 'red',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  fontWeight: 'bold',
  paddingRight: 5,
  paddingLeft: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }
});



export const StyledContainer = styled(Container)({
  maxWidth: 'lg',
  paddingTop: '40px',
  paddingBottom: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});


export const StyledSection = styled('section')({
  margin: '40px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});


export const StyledIconBox = styled('div')({
  padding: '18px 18px 18px 0px',
  textAlign: 'center',
  borderRadius: '8px',
});

export const NumberBox = styled('div')({
  backgroundColor: 'deepskyblue',
  color: 'white',
  fontSize: '20px',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px',
});




export const PriceSection = styled('section')({
  position: 'relative',
  overflow: 'hidden',
  background: 'linear-gradient(90deg, #f8f8ff 0.81%, rgba(248, 248, 255, 0) 100%)',

});



export const PricingTable = styled('div')({
  backgroundColor: '#fff',
  padding: '40px 20px',
  borderRadius: '15px',
  boxShadow: '0px 0px 20px 5px rgba(216, 216, 216, 0.4)',
  position: 'relative',
  zIndex: 1,
  marginBottom:'100px',
  transition: 'background-color 0.6s ease, color 0.6s ease', 
  '&:hover': {
    backgroundColor: 'red',
    color: 'white',
    '& a': {
      backgroundColor: 'white',
      color: 'black',
    },
    '& i': {
      color: 'black',
      transition: 'color 0.6s ease', 
    },
    '& span': {
      backgroundColor: 'white',
      transition: 'background-color 0.6s ease', 
    },
  },
  '& a, & i, & span': {
    transition: 'color 0.6s ease, background-color 0.6s ease', 
  },

  
});



export const PricingBadge = styled('div')({
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: '#FF5733',
  color: '#fff',
  padding: '5px 10px',
  borderRadius: '5px',
  fontSize: '12px',
  fontWeight: 'bold',

});

export const PricingFeatureList = styled('ul')({
  listStyleType: 'none',
  padding: 0,
  margin: 0,
});

export const PricingFeatureItem = styled('li')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  
});

export const PricingFeatureIconWrapper = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  color: '#ffffff',
  backgroundColor: 'red',
  fontSize: '10px',
  fontFamily: '"Font Awesome 6 Free"',
  fontWeight: 900,
  marginRight: '9px',
  transition: 'all 0.4s ease-in-out',
  flexShrink: 0 ,  
});

export const PricingButton = styled('a')({
  display: 'inline-block',
  padding: '10px 20px',
  width:'90%',
  backgroundColor: 'red',
  color: '#fff',
  textDecoration: 'none',
  borderRadius: '8px',
  fontWeight:600,
  textAlign: 'center',
  marginTop: '20px',
  transition: 'background-color 0.3s ease',
  '&:hover': {
  cursor:'pointer',
  }
});




export const FaqSection = styled('a')({
  position: "relative",
  backgroundColor: "#f9f9f9", 
  padding: "20px 0", 
  
});



export const ContactSection = styled('div')({
  paddingTop: '75px',
  paddingBottom: '80px',
  textAlign: 'center',
});

export const ContactImageWrapper = styled(Box)({
  textAlign: 'center',
  marginBottom: '40px',
});

export const ContactImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
});

export const FormField = styled(TextField)({
  width: '100%',
  marginBottom: '30px',
});


export const FooterWrapper = styled(Box)({
  backgroundColor: '#111', 
  paddingTop: '50px',
  paddingBottom: '50px',
  color: '#fff',
  position: 'relative',
  textAlign: 'center',
});

export const FooterLogo = styled('img')({
  height:'60px',
  marginBottom: '20px',
});

export const FooterText = styled(Typography)({
  marginBottom: '20px',
  fontSize: '18px',
  color: 'white',
});

export const SocialLinks = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '15px',
  marginBottom: '30px',
  '& i': {
    color: 'white',
  },
});


export const StyledIconButton = styled(IconButton)({
  border: '1px solid #fff',  
  borderRadius: '50%',       
  padding: '12px',          
  color: '#fff',           
  width: '48px',           
  height: '48px',           
  display: 'flex',          
  alignItems: 'center',     
  justifyContent: 'center',  
});

  


export const FooterCopyright = styled(Box)({
  marginTop: '30px',
  fontSize: '12px',
  color: '#bbb',
});
